<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
<!--            <label>显示</label>-->
<!--            <v-select-->
<!--              v-model="limit"-->
<!--              :options="limitOptions"-->
<!--              :clearable="false"-->
<!--              class="per-page-selector d-inline-block mx-50"-->
<!--            />-->
<!--            <label>条</label>-->
            <div class="demo-spacing-0">
              <b-alert
                  variant="primary"
                  show
              >
                <div class="alert-body" >
                  <span><strong>{{getCodeLabel("company_type",companyInformation.company_type)}}名称:{{companyInformation.company_name}}</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
<!--              <b-form-input-->
<!--                v-model="searchKey"-->
<!--                class="d-inline-block mr-1"-->
<!--                placeholder="搜索关键字..."-->
<!--              />-->
              <b-button
                variant="primary"
                :to="{ name: 'apps-contact-edit',query:{companyId:id,toReturnBack:toReturnBackVal}}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(contact_id)="data">
            #{{ data.item.contact_id }}
        </template>

        <template #cell(sex)="data">
          {{getCodeLabel('sex', data.item.sex)}}
        </template>

        <template #cell(is_default)="data">
          {{changeIsDefault(data.item.is_default)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${resolveStateCode(data.item.state).color}`"

          >
            {{resolveStateCode(data.item.state).label}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-contact-edit', query: { id: data.item.id ,companyId:id} }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText,getCodeLabel } from '@core/utils/filter'
import contactUseList from './contactUseList'
import contactStore from './contactStore'
import { useRouter } from '@core/utils/utils'
import companyStore from "@/views/apps/company/companyStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BAlert,
  },
  data(){
    return{
      id:0,
      companyInformation:{},
      toReturnBackVal:0,
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('contact/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    },
      toReturnBack:{
        type:Number,
        default:0,
      }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('contact')) store.registerModule('contact', contactStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contact')) store.unregisterModule('contact')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const router = useRouter()

    const changeIsDefault = function (res) {
      if (res === 0) {
        return "否"
      } else {
        return "是"
      }
    }

    const getCompany = function () {
      if (this.id==0){
        this.id = props.company_id
      }
      //yang
        if(props.toReturnBack===1){
            this.toReturnBackVal=props.toReturnBack
        }


      store.dispatch('company/view', {id: this.id}).then(res => {
        this.companyInformation = res.data.data
      })
    }

    if (router.route.value.query.id==undefined){
      router.route.value.query.id=props.company_id
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveContactTypeCode,
      resolveStateCode,
    } = contactUseList(router.route.value.query.id)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeLabel,

      // UI
      resolveContactTypeCode,
      resolveStateCode,
      changeIsDefault,
      getCompany,
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.getCompany()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
